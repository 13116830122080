li {
  list-style-type: none;
}

html,
body {
  height: 100%;
}

.loggedInAs {
  padding-right: 16px;
  color: #a78c58;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.container {
  font-family: Lato;
  background-color: #fff7ee;
  /* width: 100vw; */

  /* min-width: 300px; */
  /* height: 100vh; */
  padding: 0;
  margin: 0;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  overflow-x: hidden !important;
}

.chat {
  background: #eaeaea;
  color: #434651;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
}

.chatHeader {
  padding: 20px;
  border-bottom: 2px solid white;
  background-color: #fff;
  color: #a78c58;
}

.chatHistory {
  height: 54vh;
  padding: 10px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: auto;
  position: relative;
}

.error {
  font-weight: 600;
  margin-left: 32px;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: red;
}

.myMessageDetails {
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.messageTime {
  color: #aaacc7;
}

.messageAuthor {
  color: #aaacc7;
  text-transform: capitalize;
}

.circleBlue {
  width: 8px;
  height: 8px;
  background-color: #ccb58f;
  border-radius: 100%;
  margin-left: 4px;
}

.circleGreen {
  width: 8px;
  height: 8px;
  background-color: #8bb3bc;
  border-radius: 100%;
  margin-right: 4px;
}

.myMessageContent {
  background-color: #ccb58f;
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 12px;
  margin-bottom: 30px;
  margin-left: auto;
  width: 80%;
  position: relative;
}

.myMessageContent::after {
  bottom: 100%;
  right: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ccb58f;
  border-width: 10px;
  margin-right: -10px;
}

.otherMessageDetails {
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.otherMessageContent {
  background-color: #8bb3bc;
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 12px;
  margin-bottom: 30px;
  width: 80%;
  position: relative;
}

.otherMessageContent::after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #8bb3bc;
  border-width: 10px;
  margin-left: -10px;
}

/* form */
form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-message {
  padding: 30px;
}

form textarea {
  font-family: Lato;
  width: 80%;
  height: 48px;
  margin: auto;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.sendAndEmojiContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.emojiPicker {
  position: absolute;
  bottom: 0;
  right: 0;
}

form button {
  padding: 16px 12px;
  color: #7ea4d7;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #eaeaea;
}

form button:hover {
  padding: 16px 12px;
  color: #15436e;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #eaeaea;
}

.noMatchIdContainer {
  font-family: Lato;
  background-color: #fff7ee;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.signoutBtn{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-top:8px !important;
  color: #a78c58!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #a78c58 !important;
}

.confirmBtn{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-top:8px !important;
  color: #7da080!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #7da080 !important;
}

.firstSystemMessage{
  background-color: #fff;
  color: #7e7e7e;
  padding:10px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .chat {
    background: #eaeaea;
    color: #434651;
  }
  .flexContainer {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    width: 100% !important;
  }
}
