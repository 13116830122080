.head{
    text-align: center;
    margin-top: -20%;
}

.desc{
    font-weight: 500;
    margin: 20px 0;
}

.btnGoogle{
    background-color: #f75959 !important;
    color: #fff !important;
    text-transform: none !important;
    margin-top: 5px !important;
}

.btnFb{
    background-color: #4267B2 !important;
    color: #fff !important;
    text-transform: none !important;
    margin-top: 5px !important;
}