@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
li {
  list-style-type: none;
}

html,
body {
  height: 100%;
}

.chat_loggedInAs__3n-vG {
  padding-right: 16px;
  color: #a78c58;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.chat_container__2RazB {
  font-family: Lato;
  background-color: #fff7ee;
  /* width: 100vw; */

  /* min-width: 300px; */
  /* height: 100vh; */
  padding: 0;
  margin: 0;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  overflow-x: hidden !important;
}

.chat_chat__3bD_f {
  background: #eaeaea;
  color: #434651;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
}

.chat_chatHeader__23oHK {
  padding: 20px;
  border-bottom: 2px solid white;
  background-color: #fff;
  color: #a78c58;
}

.chat_chatHistory__1S3bl {
  height: 54vh;
  padding: 10px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: auto;
  position: relative;
}

.chat_error__gvRee {
  font-weight: 600;
  margin-left: 32px;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  color: red;
}

.chat_myMessageDetails__kMkR5 {
  font-size: 14px;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.chat_messageTime__2UnNF {
  color: #aaacc7;
}

.chat_messageAuthor__3O_gJ {
  color: #aaacc7;
  text-transform: capitalize;
}

.chat_circleBlue__wFc1U {
  width: 8px;
  height: 8px;
  background-color: #ccb58f;
  border-radius: 100%;
  margin-left: 4px;
}

.chat_circleGreen__1lHTI {
  width: 8px;
  height: 8px;
  background-color: #8bb3bc;
  border-radius: 100%;
  margin-right: 4px;
}

.chat_myMessageContent__odpWd {
  background-color: #ccb58f;
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 12px;
  margin-bottom: 30px;
  margin-left: auto;
  width: 80%;
  position: relative;
}

.chat_myMessageContent__odpWd::after {
  bottom: 100%;
  right: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #ccb58f;
  border-width: 10px;
  margin-right: -10px;
}

.chat_otherMessageDetails__3-PFj {
  font-size: 14px;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.chat_otherMessageContent__uSNlN {
  background-color: #8bb3bc;
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 12px;
  margin-bottom: 30px;
  width: 80%;
  position: relative;
}

.chat_otherMessageContent__uSNlN::after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #8bb3bc;
  border-width: 10px;
  margin-left: -10px;
}

/* form */
form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.chat_chat-message__JvpGV {
  padding: 30px;
}

form textarea {
  font-family: Lato;
  width: 80%;
  height: 48px;
  margin: auto;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.chat_sendAndEmojiContainer__3LEdS {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.chat_emojiPicker__2Az69 {
  position: absolute;
  bottom: 0;
  right: 0;
}

form button {
  padding: 16px 12px;
  color: #7ea4d7;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #eaeaea;
}

form button:hover {
  padding: 16px 12px;
  color: #15436e;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #eaeaea;
}

.chat_noMatchIdContainer__1VOVP {
  font-family: Lato;
  background-color: #fff7ee;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.chat_flexContainer__MJkbV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.chat_signoutBtn__7xG5j{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-top:8px !important;
  color: #a78c58!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #a78c58 !important;
}

.chat_confirmBtn__276gk{
  text-transform:none !important;
  padding: 0 14px !important;
  margin-top:8px !important;
  color: #7da080!important;
  border-radius: 25px !important;
  background-color: #fff !important;
  border-color: #7da080 !important;
}

.chat_firstSystemMessage__2AEna{
  background-color: #fff;
  color: #7e7e7e;
  padding:10px;
  border-radius: 8px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

@media only screen and (max-width: 768px) {
  .chat_chat__3bD_f {
    background: #eaeaea;
    color: #434651;
  }
  .chat_flexContainer__MJkbV {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    /* justify-content: space-between; */
    -webkit-align-items: center;
            align-items: center;
    width: 100% !important;
  }
}

.login_head__2Qj7F{
    text-align: center;
    margin-top: -20%;
}

.login_desc__2GtVo{
    font-weight: 500;
    margin: 20px 0;
}

.login_btnGoogle__JfK-h{
    background-color: #f75959 !important;
    color: #fff !important;
    text-transform: none !important;
    margin-top: 5px !important;
}

.login_btnFb__1ocWK{
    background-color: #4267B2 !important;
    color: #fff !important;
    text-transform: none !important;
    margin-top: 5px !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff7ee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

